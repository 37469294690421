const { log } = require('handlebars/runtime');

(function(window, $) {
  'use strict';

  var Common = {
    toSt2: function(n) {
      var s = '';
      if (n < 10) {
        s += '0';
      }
      return (s + n).toString();
    },
    baseUrlFallback: function(s) {
      if (document.baseURI === undefined) {
        var base = document.getElementsByTagName('base')[0];
        if (base !== undefined) {
          return base.href + s.replace(/^\/+/, '');
        }
      }
      return s;
    },
    offertimer: function(id, enddate) {
      var $counter = $('#' + id);

      var $expiredSlide = $counter.closest('li');
      var $slides = $expiredSlide.parents('ul').last();
      var $adtwSlides = $slides.parents('.flex-adtw').last();
      var $sliderObj = $adtwSlides.data('flexslider');

      // if there is no timer element do nothing
      if ($counter.length === 0) {
        return;
      }

      if (!enddate || enddate === '' || enddate === undefined) {
        enddate = $counter.data('countdown');
      }
      var d = new Date(),
        end = new Date(enddate * 1000),
        diff = Math.floor(end.getTime() - d.getTime()),
        count = Math.floor(diff / 1000);

      var seconds = SKTV.Common.toSt2(count % 60);
      count = Math.floor(count / 60);
      var minutes = SKTV.Common.toSt2(count % 60);
      count = Math.floor(count / 60);

      var deals = $counter.data('deals');
      var html;

      var bgColor = $counter.data('bgcolor');
      var textColor = $counter.data('textcolor');

      var dealsteasercolor = $counter.data('dealsteasercolor');

      if (bgColor === '' || bgColor === null) {
        bgColor = 'transparent';
      }

      if (textColor === '' || textColor === null) {
        textColor = '#ffffff';
      }

      $counter
        .parent()
        .toggleClass('almost-expired', diff < 86400000 && diff > 0)
        .toggleClass('expired', diff <= 0);
      $counter.parents('.catchr__content, .catchr__cell').toggleClass('almost-expired', diff < 86400000 && diff > 0);
      $counter.parents('.catchr__content, .catchr__cell').toggleClass('expired', diff <= 0);

      //tage
      if (diff > 86400000) {
        var hours = SKTV.Common.toSt2(count % 24);
        count = Math.floor(count / 24);
        var days = SKTV.Common.toSt2(count);

        if (deals === 'set') {
          html = SKTV.templates.dealsCountdownDays({ days: days, hours: hours, minutes: minutes, bgColor: bgColor, textColor: textColor });
        } else {
          html = SKTV.templates.countdownDays({ days: days, hours: hours, minutes: minutes, dealsteasercolor: dealsteasercolor });
        }

        $counter.html(html);

        // die letzten 24h
      } else if (diff < 86400000 && diff > 0) {
        var hours = SKTV.Common.toSt2(count);

        if (deals === 'set') {
          html = SKTV.templates.dealsCountdownHours({ hours: hours, minutes: minutes, seconds: seconds, bgColor: bgColor, textColor: textColor });
        } else {
          html = SKTV.templates.countdownHours({ hours: hours, minutes: minutes, seconds: seconds, dealsteasercolor: dealsteasercolor });
        }

        $counter.html(html);
      } else {
        seconds = '00';
        minutes = '00';
        var hours = '00';

        if (deals === 'set') {
          html = SKTV.templates.dealsCountdownHours({ hours: hours, minutes: minutes, bgColor: bgColor, textColor: textColor });
        } else {
          html = SKTV.templates.countdownHours({ hours: hours, minutes: minutes, seconds: seconds });
        }
        $counter.html(html);

        if (typeof $sliderObj !== 'undefined') {
          $sliderObj.removeSlide($expiredSlide.index());
          $sliderObj.flexAnimate($expiredSlide.index());
        }

        return;
      }

      setTimeout(function() {
        SKTV.Common.offertimer(id, enddate);
      }, 1000);
    },
    /**
     * To initialize Picture Gallery
     */ initSlider: function(elem, id) {
      var initialized = $(elem).data('slider');

      if (typeof initialized === 'undefined') {
        $(id)
          .find('.galleryContentList li.active')
          .flexslider({
            animation: 'slide',
            controlNav: window.ismobile ? false : 'thumbnails',
            start: function() {
              SKTV.UI.bLazy.revalidate();
            },
            after: function() {
              SKTV.UI.bLazy.revalidate();
            },
          });

        var $operators = $(id).find('.operators');
        $operators.each(function() {
          var $that = $(this);
          if ($that.find('img').length > 1) {
            $that.flexslider({ animation: 'slide', controlNav: false, slideshow: false, itemWidth: 70, itemMargin: 2 });
          }
        });
        $(elem).data('slider', true);

        // Needed for correct Operators Carousel view, sliders in overlay need also some delay
        setTimeout(function() {
          $(window).trigger('resize');
        }, 300);
      }
    },
    backToTopButton: function() {
      $('body').back2top();
    },
    removeBlinkingCursor: function() {
      //only appears on firefox input fields with readonly attribute
      $('input[readonly]').focus(function() {
        this.blur();
      });
    },
    /**
     * SEO links can also have a valid href that will not be followed if the following function can be executed.
     * This is because SK is offering e.g. the footer to other domains but sometimes without the corresponding JS for SEO.
     * To make the links work in this case, the href have a valid value leading to the correct target.
     */ initSEOLinks: function() {
      $(document).on('click', 'a[data-href]', function(e) {
        // fix for breadcrumb on kreuzfahrt.sonnenklar.tv
        if (window.location.hostname === 'kreuzfahrt.sonnenklar.tv') {
          return;
        }
        var $that = $(this),
          href = $that.data('href'),
          target = $that.attr('target');
        if (href) {
          e.preventDefault();
          if (href[0] !== '/' && !/^https?:\/\//i.test(href)) {
            href = '/' + href;
          }

          if (target) {
            window.open(href, target);
          } else {
            window.location.assign(href);
          }
        }
      });
    },
    initOffersearch: function() {
      var errors = {
          orderNr: function(number) {
            var number = number ? ' ' + number + ' ' : ' ',
              txt = ['Es wurden keine Ergebnisse für Ihre Bestellnummer', 'gefunden. Bitte überprüfen Sie die Eingabe.'];
            return txt.join(number);
          },
          empty: 'Bitte tragen Sie eine gültige Bestellnummer (z.B. 159223 oder B157052 - zwischen 6 und 7' + ' Zeichen) oder einen beliebigen Suchbegriff ein.',
        },
        orderNrPattern = /^B?\d{6}$/i;

      window.Parsley.addAsyncValidator(
        'euvia-offer',
        function(xhr) {
          return (xhr.responseJSON && xhr.responseJSON.offers) || $.Deferred().reject(errors.orderNr(xhr.responseJSON.orderId || ''));
        },
        Common.baseUrlFallback('/ajax/hasEuviaOffer/{value}')
      ).addValidator('metasearch', {
        requirementType: 'string',
        validateString: function(value) {
          var dfr = $.Deferred();

          if (value === '') {
            dfr.reject(errors.empty);
          } else if (orderNrPattern.test(value)) {
            $.get(Common.baseUrlFallback('/ajax/hasEuviaOffer/') + encodeURIComponent(value)).then(function(result) {
              if ($.isPlainObject(result) && !result.offers) {
                dfr.reject(errors.orderNr(result.orderId || ''));
              } else {
                dfr.resolve();
              }
            });
          } else {
            dfr.resolve();
          }

          return dfr;
        },
      });

      $('.order-search').each(function() {
        var $self = $(this);
        var $searchField = $('.order-search__input', $self);

        function validateEmpty() {
          $searchField.toggleClass('valid', getSearchValue() !== '');
        }

        function getSearchValue() {
          return $searchField
            .val()
            .trim()
            .replace(/\//g, '');
        }

        $self
          .parsley()
          .on('form:submit', function() {
            var value = encodeURIComponent(getSearchValue());

            if (SKTV.Tracking && SKTV.Tracking.tracker) {
              SKTV.Tracking.tracker.setType('quicksearch');
            }

            if (orderNrPattern.test(value)) {
              window.location.href = Common.baseUrlFallback('/angebot/' + value + '.html');
            } else {
              window.location.href = Common.baseUrlFallback('/suchergebnis/' + value);
            }
            return false;
          })
          .on('field:validate', function() {
            this._resetUI();
          });

        $searchField.on('input', validateEmpty).on('blur', function() {
          $(this)
            .parsley()
            .reset();
          validateEmpty();
        });

        $(window).load(validateEmpty);
      });
    },
    initStickySidebar: function() {
      $('[data-sticky]').each(function(idx, el) {
        var $self = $(el),
          $parent = $self.closest('[data-sticky-parent]');

        if (!$parent.length) {
          return;
        }

        var topOffset = $self.css('margin-top').replace('px', '') * -1 + 55;

        $(window).on('resize', function(e) {
          if ($('#sktv-search input:focus').length) {
            e.stopImmediatePropagation();
          }
        });

        function makeSticky() {
          $self.stick_in_parent({ parent: $parent, offset_top: topOffset });
        }

        if (window.innerWidth >= 960) {
          makeSticky();
        }

        //sticky responsive, also on window resize ################################
        $(window).resize(function() {
          if (window.innerWidth < 960) {
            $self.trigger('sticky_kit:detach');
          } else {
            makeSticky();
          }
        });
      });
    },
    initStrikeprice: function() {
      function toggle($el, show) {
        if (show) {
          $el.css({ transition: 'none', opacity: 1, visibility: 'visible' });
        } else {
          $el.attr('style', '');
        }
        $el.data('is-visible', show);
      }

      $(document)
        .on('mouseenter', '.strikeprice-first', function() {
          toggle($(this).find('.strikeprice-text'), true);
        })
        .on('mouseleave', '.strikeprice-first', function() {
          toggle($(this).find('.strikeprice-text'));
        })
        .on('touchstart', '.strikeprice-first', function(e) {
          e.preventDefault();
          var $text = $(this).find('.strikeprice-text');
          toggle($text, !$text.data('is-visible'));
        });

      $(document)
        .on('mouseenter', '.priceborder', function() {
          toggle($(this).find('.price-text'), true);
        })
        .on('mouseleave', '.priceborder', function() {
          toggle($(this).find('.price-text'));
        })
        .on('touchstart', '.priceborder', function(e) {
          e.preventDefault();
          var $text = $(this).find('.price-text');
          toggle($text, !$text.data('is-visible'));
        });

      $(document)
        .on('mouseenter', '.strikeprice-second', function() {
          toggle($(this).find('.second-strikeprice-text'), true);
        })
        .on('mouseleave', '.strikeprice-second', function() {
          toggle($(this).find('.second-strikeprice-text'));
        })
        .on('touchstart', '.strikeprice-second', function(e) {
          e.preventDefault();
          var $text = $(this).find('.second-strikeprice-text');
          toggle($text, !$text.data('is-visible'));
        });
    },
    addFramedContentAdditions: function() {
      /* check if content is in iframed lightbox AND iOS AND touch => add fix for jumping to iframe top on css
            / html changes of DOM */
      var isIframed = $('body').hasClass('lightbox-iframe__body--margin'),
        isIOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/),
        isTouch = 'ontouchstart' in document.documentElement;

      if (isIframed && isIOS && isTouch) {
        $('<style type="text/css">html,body{overflow:auto;-webkit-overflow-scrolling:touch;}</style>').appendTo($('head'));
        $('body')
          .toggleClass('isIframed', isIframed)
          .toggleClass('isIOS', isIOS)
          .toggleClass('isTouch', isTouch);
      }
    },
    getSaveGUID: function(id) {
      return id.replace(/[^a-zA-Z0-9]/g, '_');
    },
    getView: function(key, dataset, callback) {
      $.ajax({
        url: '/ajax/getView',
        data: { key: key, dataset: JSON.stringify(dataset) },
        type: 'POST',
        success: function(data) {
          if (typeof callback === 'function') {
            callback(data);
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          console.log('Error > getView: ', jqXHR, textStatus, errorThrown);

          if (typeof callback === 'function') {
            callback(false);
          }
        },
      });
    },
    getGoogleMapsKey: function() {
      return 'AIzaSyAnEQnhh5ryHW5XENkOVCSdAIgAgMb25UM';
    },
    checkByLuhn: function(val) {
      var nCheck = 0,
        nDigit = 0,
        bEven = false;
      val = val.replace(/\D/g, '');

      for (var n = val.length - 1; n >= 0; n--) {
        var cDigit = val.charAt(n),
          nDigit = parseInt(cDigit, 10);

        if (bEven) {
          if ((nDigit *= 2) > 9) {
            nDigit -= 9;
          }
        }

        nCheck += nDigit;
        bEven = !bEven;
      }

      return nCheck % 10 === 0;
    },
  };

  var Browser = {
    isChrome: function() {
      var ua = navigator.userAgent.toLowerCase();
      return window.chrome !== null && window.chrome !== 'undefined' && window.navigator.vendor === 'Google' + ' Inc.' && typeof window.opr === 'undefined' && ua.indexOf('edge') === -1 && ua.indexOf('chrome') !== -1;
    },
    isOpera: function() {
      return typeof window.opr !== 'undefined';
    },
    isSafari: function() {
      var ua = navigator.userAgent.toLowerCase();
      return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
    },
    isMobile: function() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    _isMobileSafari: undefined,
    isMobileSafari: function() {
      // prevent numerious times doing mobile check and safari check again and again
      if (Common.Browser._isMobileSafari === undefined) {
        Common.Browser._isMobileSafari = Common.Browser.isSafari() && Common.Browser.isMobile();
      }

      return Common.Browser._isMobileSafari;
    },
    isInternetExplorer: function() {
      var ua = window.navigator.userAgent,
        msie = ua.indexOf('MSIE ');

      return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    },
    isEdge: function() {
      return window.navigator.userAgent.indexOf('Edge/') > 0;
    },
    _isTouch: undefined,
    isTouch: function() {
      if (Common.Browser._isTouch === undefined) {
        Common.Browser._isTouch = 'ontouchstart' in document.documentElement;
      }

      return Common.Browser._isTouch;
    },
    isIPad: function() {
      return navigator.userAgent.match(/iPad/i) !== null;
    },
    hasSKTV_Referrer: function() {
      return document.referrer.match(/^https?:\/\/([^\/]+\.)?sonnenklar\..*/i);
    },
    inIframe: function() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    analyze: function() {
      console.log(
        'Chrome: ' +
          this.isChrome() +
          '\n' +
          'Safari: ' +
          this.isSafari() +
          '\n' +
          'IE: ' +
          this.isInternetExplorer() +
          '\n' +
          'Edge: ' +
          this.isEdge() +
          '\n' +
          'Opera: ' +
          this.isOpera() +
          '\n' +
          'Is Touch Device: ' +
          this.isTouch() +
          '\n' +
          'Mobile Device: ' +
          this.isMobile() +
          '\n'
      );
    },
  };


  window.SKTV = window.SKTV || {};
  window.SKTV.Common = Common;
  window.SKTV.Common.Browser = Browser;
})(window, $);
