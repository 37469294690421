(function (window, $) {
  'use strict';
  var Tealium = {
    init: function () {
      this.teaserData();
    },
    teaserData: function () {
      $(document).on('click', '.teaser a', function (e) {

        var teaserLi = $(this).closest('[data-tr]');
        if (!teaserLi.length) {
          return;
        }

        var teaserData = teaserLi.attr('data-tr');
        if (!teaserData) {
          return;
        }

        var teaserParts = teaserData.split('-_-');
        var pageID = teaserParts[0];
        var teaserOfferId = teaserParts[2].split('|')[1] || "";
        var teaserPosition = teaserParts[1];
        var teaserTitle = teaserParts[2].split('|')[0] || "";
        var teaserName = teaserParts[3].split('|')[0] || "";
        var teaserTopic = teaserParts[4] || "";
        var teaserRoulettePosition = teaserParts[5] || "";
        var teaserCta = extractLowestPrice(teaserLi.find('.price')) || $($(this).get(0)).find('.button').text().trim();

        utag.link({
          "tealium_event": "teaser",
          "teaser_pageID": pageID,
          "teaser_position": teaserPosition,
          "teaser_offer_id": teaserOfferId,
          "teaser_name": teaserName,
          "teaser_title": teaserTitle,
          "teaser_topic": teaserTopic,
          "teaser_roulette_position": teaserRoulettePosition,
          "teaser_cta": teaserCta
        });

        var href = $(this).attr('href');
        if (href) {
          setTimeout(function () {
            window.location.href = href;
          }, 100);
        }

        if ($(this).attr('target') !== "_blank") {
          e.preventDefault();
        }

      });
    }
  };

  function extractLowestPrice(priceElement) {
    let prices = [];

    priceElement.contents().each(function() {
      let priceText = $(this).text().trim().replace(/[^\d,.-]/g, '').replace(',', '.');
      let priceValue = parseFloat(priceText);
      if (!isNaN(priceValue)) {
        prices.push(priceValue);
      }
    });

    return prices.length > 0 ? Math.min(...prices) : null;
  }

  $(document).ready(function () {
    window.SKTV = window.SKTV || {};
    window.SKTV.Tealium = Tealium;
  });

})(window, jQuery);
